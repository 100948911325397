import {ListingCatch} from "./listingCatch";

export class Listing {

    id = '';

    createdAt!: Date;
    updatedAt!: Date;
    createdBy= '';

    items: ListingCatch[] = [];
    speciesList = [''];
    price!: number;
    name = '';
    description = '';
    image_url = '';
    hidden!: boolean;
    group = ''; // This MUST be the Mongo ID of the group you are posting under

    owner_id= '';
    batch_tag= '';

    deletions = [''];

    constructor(init?: Partial<Listing>) {
        if (!init || !init.items) {
            this.items = [];
        }

        if (init) {
            Object.assign(this, init);
        }
    }

    isValid(): boolean {
        return this.notNull([this.name, this.batch_tag, this.items, this.price]);
    }

    hasValidStrings(): boolean {
        return this.notNull([this.name, this.items, this.price]);
    }

    private notNull(inputs: any): boolean {
        let flag = true;
        for (let item of inputs) {
            if (!item || item === null || item === undefined || item === "") {
                flag = false;
            }
        }
        return flag;
    }
}
