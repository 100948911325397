import { Component } from '@angular/core';

@Component({
  selector: 'app-gooey-loader',
  templateUrl: './gooey-loader.component.html',
  styleUrls: ['./gooey-loader.component.css']
})
export class GooeyLoaderComponent {

  constructor() { }

}
