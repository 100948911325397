<div class="list-group">
    <div href="" class="list-group-item active">
        <h4>Trip information</h4>
    </div>
    <div *ngIf="cc.trip_bait_used" href="" class="list-group-item">
        <h4 class="list-group-item-heading">Bait Used:</h4>
        <p class="list-group-item-text">{{cc.trip_bait_used}}</p>
    </div>
    <div *ngIf="cc.trip_boat_name" href="#" class="list-group-item">
        <h4 class="list-group-item-heading">Boat Name:</h4>
        <p class="list-group-item-text">{{cc.trip_boat_name}}</p>
    </div>
    <div *ngIf="cc.trip_crew_number" href="#" class="list-group-item">
        <h4 class="list-group-item-heading">Number of crewmen:</h4>
        <p class="list-group-item-text">{{cc.trip_crew_number}}</p>
    </div>
    <div *ngIf="cc.trip_weather" href="#" class="list-group-item">
        <h4 class="list-group-item-heading">Weather Conditions:</h4>
        <p class="list-group-item-text">{{cc.trip_weather}}</p>
    </div>
    <div *ngIf="cc.trip_sea_condition" href="#" class="list-group-item">
        <h4 class="list-group-item-heading">Sea Conditions:</h4>
        <p class="list-group-item-text">{{cc.trip_sea_condition}}</p>
    </div>
    <div *ngIf="timeDiff" href="#" class="list-group-item">
        <h4 class="list-group-item-heading">Trip Duration:</h4>
        <p class="list-group-item-text">{{timeDiff}}</p>
    </div>
</div>
