<div class="purchase-details">
    <div>
        <div class="fail-icon">
            <img class="cross-image" src="assets/X-marks-the-spot.svg">
        </div>
        <div class="fail-text">
            <h3>{{ 'TRANSACTION_FAILED_UNSUCCESSFUL' | translate }}</h3>
            <p>{{ 'TRANSACTION_FAILED_CANCELLED' | translate }}</p>
        </div>
    </div>
</div>
