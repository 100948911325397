import { Injectable } from '@angular/core';
import { BaseService } from '../../services/base.service';

@Injectable({
    providedIn: 'root'
})
export class TenantService extends BaseService {
    private readonly CLASS_NAME = 'TenantService';

    public tenantCode!: string;
    public config: any;

    public dateFormat = '';
    public timeFormat = '';
    public dateTimeFormat = '';
    public nameDayMonthFormat = '';


    setTenantCode(code: string) {
        console.log(this.CLASS_NAME + '.setTenantCode code', code);
        this.tenantCode = code;
        this.loadConfigs(this.tenantCode);
        console.log(this.CLASS_NAME + '.setTenantCode config', this.config);
    }

    private loadConfigs(tenantCode: string): void {
        this.config = {
            ...require(`../../../assets/tenants/${tenantCode}/hook-to-cook/config.json`)
        };

        // Make a local copy of the date format variables - just to reduce the length of the names
        // when they're used elsewhere.
        this.dateFormat = this.config.dateFormat;
        this.timeFormat = this.config.timeFormat;
        this.dateTimeFormat = this.config.dateTimeFormat;
        this.nameDayMonthFormat = this.config.nameDayMonthFormat;
    }
}
