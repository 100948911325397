import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer} from '@angular/platform-browser';

@Pipe({ name: 'truncate' })
export class TruncatePipe implements PipeTransform {
    constructor() {

    }

    transform(input: string, limit: number) {

        // if input is not null or undefined
        if (input) {
            return input.substring(0, limit) + "...";
        } else {
            return "";
        }
    }
}
