// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    production: false,

    version: require('../../package.json').version,
    stage: 'staging',

    urls: {
        SERVER: {
            protocol: 'https',
            prefix: 'server',
            domain: 'staging.abalobi.org',
            port: ''
        },

        MARKET: {
            protocol: 'https',
            prefix: 'market',
            domain: 'staging.abalobi.org',
            port: ''
        },

        PUBLIC: {
            protocol: 'https',
            prefix: 'public',
            domain: 'staging.abalobi.org',
            port: ''
        }
    }
};
