import { Injectable, inject } from '@angular/core';
import { environment } from '../environments/environment';
import { Meta } from '@angular/platform-browser';
import { BaseService } from './services/base.service';
import { TenantService } from './components/tenant/tenant.service';


@Injectable({
    providedIn: 'root'
})
export class AppInitService extends BaseService {
    private readonly CLASS_NAME = 'AppInitService';
    private readonly HOOKTOCOOK_PREFIX = 'hooktocook';

    private meta = inject(Meta);
    private tenantService = inject(TenantService);



    setTenantCode() {
        const [urlPrefix, tenantCode] = window.location.host.split('.')[0].split('-');

        console.log(this.CLASS_NAME + '.init() - urlPrefix:', urlPrefix, '\ntenantCode:', tenantCode);

        if (urlPrefix.toLowerCase() === this.HOOKTOCOOK_PREFIX && tenantCode) {
            this.tenantService.setTenantCode(tenantCode);
            BaseService.setUrls(tenantCode, environment.urls);
        } else {
            alert('Tenant code not found. The application cannot run. Make sure you\'re running a URL that is listed in your hosts file!  Your one (' + window.location.host + ') is invalid.');
            const errorMsg = 'Invalid URL format: Tenant code could not be extracted';

            console.error(this.CLASS_NAME + '.init() - error:', errorMsg);
            throw new Error(errorMsg);
        }
    }

    init(): Promise<string> {
        return new Promise((resolve) => {
            this.setTenantCode();

            const parts = window.location.href.split('/');
            console.log(this.CLASS_NAME + '.init() - location.href', parts);

            if (!parts[4]) {
                resolve('success');
            }

            const url = `${AppInitService.SERVER_URL}/api/catch-tag-h2c-minimal/${parts[4]}`;
            console.log(this.CLASS_NAME + '.init() - url', url);

            this.httpClient.get(url).subscribe({
                next: (data: any) => {
                    console.log(this.CLASS_NAME + '.init() - RESPONSE', data);

                    this.meta.addTag({
                        name: 'og:image',
                        content: data.metaImage
                    });

                    resolve('success');
                },
                error: error => {
                    console.log(this.CLASS_NAME + '.init() - ERROR', error);
                    this.meta.addTag({
                        name: 'og:image',
                        content: `${AppInitService.PUBLIC_URL}/images/hook_to_cook/meta-default.jpg`
                    });

                    resolve('success');
                }
            });
        });
    }
}
