import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { TenantService } from './components/tenant/tenant.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})

export class AppComponent implements OnInit{
    title = 'Hook to Cook';


    constructor(
        private tenantService: TenantService,
        private router: Router,
        private translate: TranslateService) {
        // listens to any routing  (changing of pages) and logs that as a pageview in google analytics
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                (<any>window).ga('set', 'page', event.urlAfterRedirects);
                (<any>window).ga('send', 'pageview');
            }
        });     
    }


    ngOnInit() {
        this.translate.setDefaultLang(this.tenantService.config.languageDefault);
    }
    
}

