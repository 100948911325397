import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';

import { Species } from '../classes/species';



@Injectable()
export class SpeciesInfoService {
    private readonly CLASS_NAME = 'SpeciesInfoService';

    species: Species[] = [];


    constructor(private httpClient: HttpClient) {
        console.log(this.CLASS_NAME + '.constructor()');

        this.loadSpecies();
    }


    async loadSpecies() {
        console.log(this.CLASS_NAME + '.loadSpecies()');

        const url = '../assets/data/species.json';
        console.log(url);

        this.species = await firstValueFrom(this.httpClient.get<Species[]>(url));
        console.log(this.CLASS_NAME + '.loadSpecies() secies', this.species);
    }


    getSpecies() {
        console.log(this.CLASS_NAME + '.getSpecies()', this.species);

        return this.species;
    }


    getSpeciesSearch(SASSI_ID: number): Species | null {
        console.log(this.CLASS_NAME + '.getSpeciesSearch()', SASSI_ID);

        const specie = this.species.find(item => item.id === SASSI_ID);
        console.log(this.CLASS_NAME + '.getSpeciesSearch() - specie', specie);

        if (!specie) {
            console.warn(this.CLASS_NAME + '.getSpeciesSearch() - Specie not found for SASSI_ID: ' + SASSI_ID);
            return null;
          }

        return specie;
    }

}
