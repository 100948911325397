import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseService } from './base.service';


@Injectable({
    providedIn: 'root'
})
export class CatchService extends BaseService {
    private readonly CLASS_NAME = 'CatchService';


    getCatchMethodKey(method: string) {
        const translations: { [key: string]: string } = {
            sea_rough: 'Rough',
            sea_very_rough: 'Very Rough',
            sea_calm: 'Calm',
            weather_cloudy: 'Cloudy',
            weather_misty: 'Misty',
            weather_partlycloudy: 'Partly Cloudy',
            weather_rainy: 'Rainy',
            weather_sunny: 'Sunny',
            diving: 'Diving',
            handline: 'Hand-line',
            hand: 'By hand',
            net_gill: 'Gill net',
            net_hoop: 'Hoop net',
            net_throw: 'Throw net',
            net_trek: 'Trek net',
            pump: 'Pump',
            rod: 'Fishing rod',
            trap: 'Trap',
            not_on_list: 'Other',
            undefined: 'Not provided',
            null: 'Not provided'
        };

        if (translations[method]) return method;
        return Object.keys(translations).find(key => translations[key] === method);;
    }

    getH2cData(id: string, idType = 'batch_tag') {
        console.log(this.CLASS_NAME + '.getH2cData()', id, idType);

        const urlLookup: any = {
            community: '/api/communities?string-id=',
            fisher_id: '/api/users/',
            product: '/api/products?string-id=',
            species: '/api/species?string-id=',
            establishment: '/api/h2c/staticqr/'
        }

        const path = urlLookup[idType] ?? '/api/catch-tag-h2c/';
        const url = CatchService.SERVER_URL + path + id;

        console.log(this.CLASS_NAME + '.getH2cData() Full request URL:', url);

        return this.httpClient.get(url);
    }


    sendThankYouSMS(toNumber: string | undefined, messageBody: string, userContactDetail: string, fisherName: string) {

        const url = `${CatchService.SERVER_URL}/api/sms_h2c`;
        // build sms
        const body = {
            toNumber : toNumber,
            messageBody : messageBody,
            timeStamp : new Date().getTime(),
            userContactDetail : userContactDetail,
            toFisher : fisherName
        };

        // call endpoint on main server to send sms
         return this.httpClient.post(url, body);
    }


    reportEstablishment(reported_establishment: string, reporter_name: string | null, reporter_email_address: string | null, tag: string, reason: string): Observable<any> {
        // build establishment report
        const body = {
            reported_establishment : reported_establishment,
            reporter_name : reporter_name,
            reporter_email_address : reporter_email_address,
            tag : tag,
            reason : reason
        };
        // call endpoint on main server to send sms
        return this.httpClient.post(CatchService.SERVER_URL + '/api/report-establishment', body);
    }


    getMethodInfo(method: string): Observable<any> {
        const url = `${CatchService.SERVER_URL}/api/method-info/${method}`;

        return this.httpClient.get(url);
    }
}
