import * as _ from "lodash";

export class BatchCommunity {
    Id?: number;
    Name? = '';
    name_afr__c? = '';
    name_eng__c = '';
    gps_lat__c!: number;
    gps_lon__c!: number;
    region__c? = '';
    comments__c = '';
    image_url__c = '';
    province_abbreviation__c? = '';
    daff_prefix__c? = '';
}

class BatchFisher {
    abalobi_id__c = '';
    FirstName__c = '';
    LastName__c = '';
    contact_mobile_num__c? = '';
    image_url__c? = '';
    primary_community__c? = '';
    tag? = '';
    h2c_pic_boat__c? = '';
    h2c_pic_profile__c? = '';
    h2c_boat_description__c? = '';
    h2c_pic_profile_2__c? = '';
    h2c_blurb__c = '';
    h2c_quote_1__c? = '';
    h2c_quote_1_pic__c? = '';
    h2c_quote_1_name__c? = '';
    h2c_quote_2__c? = '';
    h2c_quote_2_pic__c? = '';
    h2c_quote_2_name__c? = '';
    h2c_quote_3__c? = '';
    h2c_quote_3_pic__c? = '';
    h2c_quote_3_name__c? = '';
    h2c_quote_4__c? = '';
    h2c_quote_4_pic__c? = '';
    h2c_quote_4_name__c? = '';
    h2c_sms_prompt__c? = '';
    h2c_sms_prompt_name__c? = '';
    h2c_video_url__c? = '';
    h2c_video_intro__c? = '';
    h2c_pic_cultural_1__c? = '';
    h2c_pic_cultural_2__c? = '';
    h2c_pic_cultural_3__c? = '';
    h2c_pic_cultural_4__c? = '';
    h2c_cultural_perspectives_blurb__c? = '';


}

class BatchSpecies {
    Id?: number;
    name_eng__c = '';
    DEPRECATED_sf_id? = '';
    image_url__c= '';
    Ablb_Species_Writeup__c= '';
    Ablb_sassi_id__c!: number;
}

class BatchHow {
    boat_imgUrl = '';
    boat_title? = '';
    boat_description = '';
    weather = '';
    sea_condition = '';
    method = '';
    num_crew!: number;
}

export class Batch {
    fishers: BatchFisher[] = [];
    species: BatchSpecies[] = [];
    communities: BatchCommunity[] = [];
    how: BatchHow[] = [];
    boatUrls: { [boatImgUrls: string]: string } = {};

    constructor(init?: Partial<Batch>) {
        if (init) {
            Object.assign(this, init);
            
            // Filter out non-unique fishers for H2C display purposes
            this.fishers = _.uniqBy(this.fishers, "abalobi_id__c");
        }
    }
}
