import {Component, OnInit, OnChanges} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Router, NavigationStart} from '@angular/router';
import {Location} from '@angular/common';
import {Batch} from "../../classes/batch";

import {Catch} from '../../classes/catch';
import {Species} from '../../classes/species';

import {CatchService} from '../../services/catch.service';
import {SpeciesInfoService} from '../../services/species-info.service';
import {MarketapiProvider} from "../../services/marketplace.service";

@Component({
    selector: 'app-catch-detail',
    templateUrl: './catch-detail.component.html',
    styleUrls: ['./catch-detail.component.scss']
})

export class CatchDetailComponent implements OnInit, OnChanges {
    private readonly CLASS_NAME = 'CatchDetailComponent';


    /*============================================================================
        Variables
    ============================================================================*/
    cCatch: Catch = new Catch();
    cBatch: Batch = new Batch();

    lat!: number;
    lng!: number;

    boat_img_url = '';

    sassi_id!: number;

    species_writeup = '';
    community_info = '';

    deleteFlag = true;

    currentSpecies: Species | null = null;
    currentSpecStr = '';

    searchedCatchId = '';
    catchId = '';

    // Will be true when /fisher is accessed without ID. Just used so that spinner is not shown then
    noFisher = true;

    // When true the fisher with catchID could not be found
    showError = false;

    // binds to the thank you sms form input in the template
    thankYouMsg = '';

    /* sets if fisher blurbs should be truncated or not
    This is initialized to a boolean array with length equal to the number of fishers in the batch, all entries false
    Values are then toggled from the template according to user button presses
     */
    truncateBlurb = [true];

    // The name of the co-op that listed this batch
    batch_coop_name = null;

    /*============================================================================
        Public Methods
     ============================================================================*/

     mapOptions: google.maps.MapOptions = {
        center: {lat:  0, lng: 0},
        zoomControl: true,
        zoom: 6.5,
        disableDefaultUI: true,
        streetViewControl: false
    };
    markerOptions: google.maps.MarkerOptions = {};

    public replaceBreaks(input: string): string {
        let returnMe = input;
        if (input === null) {
            return input;
        } else {
            try {
                const newVar = input.toString().split('%endline%').join('\n');
                returnMe = newVar;
                // console.log(newVar);
                return returnMe;
            } catch (ex) {
                // alert("ERROR!");
                console.log(ex);
                return input;
            }
        }
    }


    public replaceAll(input: string, search: string, replacement: string): string {
        let returnMe = input;
        try {
            const newVar = input.toString().split(search).join(replacement);
            alert(newVar);
            returnMe = newVar;

            return returnMe;
        } catch (ex) {
            // alert("ERROR!");
            console.log(ex);
            return input;
        }
    }


    public replaceLeadingWhiteSpace(s: string): string {
        // let newStr = "";
        return s.replace(/^\s+/,
            function (m: string) {
                return m.replace(/\s/g, '.');
            }
        );
        // return newStr;
    }


    /*============================================================================
        Constructor
     ============================================================================*/
    constructor(private fisherService: CatchService,
                private speciesInfoService: SpeciesInfoService,
                private route: ActivatedRoute,
                private location: Location,
                private router: Router,
                private market: MarketapiProvider) {
    }


    ngOnInit() {

        this.router.events.subscribe((event) => {

            if (event instanceof NavigationStart && this.deleteFlag) {
                this.cCatch = new Catch();
            }

            this.deleteFlag = true;
        });

        this.route.params.subscribe(params => {

            if (!params['id']) {
                console.log('ID param not present');

                return;
            }

            this.noFisher = false;
            this.catchId = params['id'];

            this.fisherService.getH2cData(this.catchId).subscribe((response) => {
                console.log(response, 'res');
                this.handleTagResult(response);
            }, error => {
                console.log('ERROR', error);
                this.showError = true;
            });
        });



        // get name of the co-op from the id of the batch
        this.market.getGroupName(this.catchId).subscribe({
            next: (data: any) => {
                console.error('Batch co-op name: ' + data.name);

                // handle null and undefined results (the result will be null if the tag is a catch and not batch tag or the tag does not exist)
                if (data.name !== null && data.name !== undefined) {
                    this.batch_coop_name = data.name;
                }
            },
            error: error => {
                console.error(this.CLASS_NAME + '.getGroupName() - ERROR: ', error);
            }
        });
    }


    ngOnChanges(changes: any) {
        this.getBoatTypeImage();
    }


    /**
     * Needed to het the catch to pass onto child components
     * @returns {Catch}
     */
    getThisCatch(): Catch {
        return this.cCatch;
    }

    /*============================================================================
        Void Methods
     ============================================================================*/

    searchFisher(): void {
        // console.log('Text in search box = ' + this.searchedCatchId);
        this.catchId = this.searchedCatchId;

        this.showError = false;
        this.cCatch = new Catch();

        // Use this to navigate to new page url for page update
        this.deleteFlag = false;
        this.router.navigateByUrl('catch/' + this.catchId);

        // Enable this to just update the page as is
        this.fisherService.getH2cData(this.catchId)
            .subscribe(tagResult => {
                this.handleTagResult(tagResult);
            });
    }


    debugButton(): void {
        // const test = response.json()['species'];
        // const test = this.fisher;
        // console.log(this.fisherInfo[1]['common_name']);
        // this.filterDataById();
        // alert(this.currentSpecies);
        console.log("Current species:\n" + this.currentSpecies);

    }


    handleTagResult(tagResult: any): void {

        console.log(tagResult);

        if (!tagResult['lookup_type']) {
            return;
        }

        this.cCatch = new Catch();
        this.cBatch = new Batch();

        if (tagResult.lookup_type === "CATCH") {
            // Handle displaying a catch
            const CATCH = new Catch(tagResult.lookup_data);
            this.setValues(CATCH);
        } else if (tagResult.lookup_type === "BATCH") {
            // Handle displaying a batch
            const BATCH = new Batch(tagResult.lookup_data);
            console.log(tagResult.lookup_data);
            this.showBatch(BATCH);

            // create all true array (all fisher blurbs start collapsed
            this.truncateBlurb = new Array(BATCH.fishers.length).fill(true);
        } else {
            // Error, no catch found
            this.showError = true;
        }
    }


    getBoatTypeImage(): void {
        console.log(`Boat type after cleaning: ${this.cCatch?.trip_boat_type}`);
        if (this.cCatch?.trip_boat_type) {
            switch (this.cCatch?.trip_boat_type.toLowerCase()) {
                case 'bakkie':
                case 'boat_bakkie':
                case 'Boat Bakkie':
                    this.boat_img_url = 'assets/images/bakkie.jpg';
                    break;
                case 'chukkie':
                case 'boat_chukkie':
                case 'Boat Chukkie':
                    this.boat_img_url = 'assets/images/chukkie.jpg';
                    break;
                case 'ski' :
                case 'boat_skiboat':
                case 'boat_ski':
                case 'Boat Skiboat':
                    this.boat_img_url = 'assets/images/skiboat.JPG';
                    break;
            }
        }
        console.log(`Boat type after processig: ${this.cCatch?.trip_boat_type}`);
    }


    setValues(fisher: Catch): void {

        this.cCatch = fisher;
        // this.cCatch.SASSI_ID = 16;
        this.lat = fisher.community_lat;
        this.lng = fisher.community_lon;
        this.sassi_id = this.cCatch.SASSI_ID;
        // this.cCatch.trip_boat_type = 'bakkie';
        this.getBoatTypeImage();

        switch (this.cCatch.fisher_image) {
            case null:
            case undefined:
                this.cCatch.fisher_image = 'http://www.clipartbest.com/cliparts/KTn/XpX/KTnXpXdjc.png';
                break;
        }

        this.currentSpecStr = JSON.stringify(this.currentSpecies);

        this.community_info = this.replaceLeadingWhiteSpace(this.replaceBreaks(fisher.community_info));
        this.species_writeup = this.replaceBreaks(fisher.species_info);

        const specie =  this.speciesInfoService.getSpeciesSearch(this.sassi_id);
        if (specie) {
            this.currentSpecies = specie
        }

    }


    showBatch(batch: Batch): void {
        console.log(batch);
        console.log(" - showBatch()");
        this.cBatch = batch;
    }


    snapScanButton(): void {
        // alert("BUTTON CLICKED!");
        //TODO MT refactoring - Should this change to MT url?
        //TODO MT refactoring - This doesn't seem to be called anywhere in the cod
        window.location.href = `https://pos.snapscan.io/qr/tR9I08gi?id=${this.catchId}&mainFisher=${this.cCatch?.fisher_name}`;
    }


    getCatchId(): string {
        return this.catchId;
    }


    goBack(): void {
        this.location.back();
    }


    // called on send sms button click
    sendThankYouSMS(): void {

        // loop through the cell numbers of all the fishers associated with this batch
        for (let i = 0; i < this.cBatch?.fishers.length; i++) {

            // if fisher has a number
            if (this.cBatch?.fishers[i].contact_mobile_num__c !== null && this.cBatch?.fishers[i].contact_mobile_num__c !== undefined) {

                console.log("Fisher number: " + this.cBatch.fishers[i].contact_mobile_num__c);

                // build sms and send to fisherService to POST it to the appropriate endpoint
                this.fisherService
                    .sendThankYouSMS(this.cBatch.fishers[i].contact_mobile_num__c,
                        "[Abalobi] SMS from customer eating your catch: " + this.thankYouMsg, "", "")
                    .subscribe((response) => {
                            // log server response
                            console.log(response);
                        }
                    );
            }else {
                console.log("Fisher number empty");
            }
        }
    }


    // toggles between truncated and non-truncated blurb (see less and see more)
    toggleTruncateBlurb(i: number): void {
        this.truncateBlurb[i] = !this.truncateBlurb[i];
    }

}
