import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { BatchCommunity } from '../classes/batch';
import { Community } from '../classes/community';
import { CommunityLookup } from '../classes/community-lookup';
import { TenantService } from '../components/tenant/tenant.service';
import { BaseService } from './base.service';

@Injectable()
export class CommunityInfoService extends BaseService {
    private readonly CLASS_NAME = 'CommunityInfoService';

    private communities: Community[] = [];
    private communityLookups: { [key: string]: CommunityLookup } = {};
    public qcCommunitiesInfo: Community[] = [];

    constructor(
        private tenantService: TenantService
    ) {
        super();
    }


    async loadCommunitiesAndLookups() {
        console.log(this.CLASS_NAME + '.loadCommunitiesAndLookups()');

        return Promise.all([this.loadCommunities(), this.loadCommunityLookups()]);
    }


    public async loadCommunities() {
        console.log(this.CLASS_NAME + '.loadCommunities()');

        const url = `${BaseService.ASSETS_URL}/data/tenants/${this.tenantService.tenantCode}/hook-to-cook/communities.json?timeNow=${new Date().getTime()}`;
        console.log(this.CLASS_NAME + '.loadCommunities() url:', url);

        try {
            const communities = await firstValueFrom(this.httpClient.get<{ [key: string]: Community }>(url));
            const dataArray: Community[] = Object.values(communities ?? {}); // Ensure communities is an object before converting to array
            this.communities = dataArray ?? []; // Fallback to an empty array if no data
        } catch (error) {
            console.error(`${this.CLASS_NAME}.loadCommunities() failed`, error);
            this.communities = [];
        }
    }

    public async loadCommunityLookups() {
        console.log(this.CLASS_NAME + '.loadCommunityLookups()');

        const url = `${BaseService.ASSETS_URL}/data/tenants/${this.tenantService.tenantCode}/hook-to-cook/communityLookup.json?timeNow=${new Date().getTime()}`;
        console.log(this.CLASS_NAME + '.loadCommunityLookups() url:', url);

        try {
            this.communityLookups = await firstValueFrom(this.httpClient.get<{ [key: string]: CommunityLookup }>(url));
            if (this.communityLookups == null) {
                this.communityLookups = {};
            }
        } catch (error) {
            console.error(`${this.CLASS_NAME}.loadCommunityLookups() failed`, error);
            this.communityLookups = {};
        }

        this.communityLookups = this.#topLevelKeysToLowerCase(this.communityLookups);

        console.log(this.CLASS_NAME + '.loadCommunityLookups() communityLookups:', this.communityLookups);
    }


    /**
     * Used to convert the keys of this.communityLookups to lowercase to make the lookup case-insensitive.
     * @param obj 
     * @returns Same object with lowercase keys
     */
    #topLevelKeysToLowerCase(obj: any): any {
        return Object.keys(obj).reduce((acc: any, key: string) => {
            const lowerCaseKey = key.toLowerCase(); // Convert the top-level key to lowercase
            acc[lowerCaseKey] = obj[key]; // Preserve the original value
            return acc;
        }, {});
    }
    

    /**
     * Fetches the community object given the passed community name.
     * @param name
     * @returns
     */
    public getCommunityByName(name: string): Community | null {
        console.log(this.CLASS_NAME + '.getCommunityByName()');

        if (this.communities == null || this.communities.length === 0) {
            console.error('Communities data not loaded');
            return null;
        }

        // Find the community where the name matches, ignoring case
        let community = this.communities.find(
            (c) => c.name.toLowerCase() === name.toLowerCase()
        );

        if (community == null) {
            console.warn(`Community with name '${name}' not found, defaulting to 'Abalobi'`);
            community = this.communities.find(
                (c) => c.name.toLowerCase() === 'abalobi'
            );
        }

        if (community == null) {
            console.warn(`Community with name '${name}' not found`);
            return null;
        }

        return community;
    }

    /**
     * Looks up the QCCommunities for the passed community name.
     * Note that one fisher community may be associated with multiple
     * QC communities.
     * @param name
     * @returns
     */
    public getQCCommunities(name: string): string[] {
        console.log(this.CLASS_NAME + `.getQCCommunities(${name})`, this.communityLookups);
        name = name.trim().toLowerCase();

        if (this.communityLookups == null || Object.keys(this.communityLookups).length === 0) {
            console.error(this.CLASS_NAME + '.getQCCommunities() - QC Community Lookup data not loaded');
            return [];
        }

        if (this.communityLookups[name] == null) {
            console.error(this.CLASS_NAME + `.getQCCommunities() - Community '${name}' NOT in Lookup`);
            return [];
        }

        const communityArray = this.communityLookups[name].qcCommunities;
        console.log(this.CLASS_NAME + '.getQCCommunities() - communityArray', communityArray);

        return communityArray ?? [];
    }

    /**
     * buildQcCommunitiesInfo
     * Builds up an array of communities info for the POST_CATCH section of the catch component
     * @param communities - array of community names
     * @returns array of objects containing QC community details
     */
    public buildQcCommunitiesInfo(communities: BatchCommunity[]): Community[] {
        console.log(this.CLASS_NAME + '.buildQcCommunitiesInfo()', communities);

        communities.forEach(item => {
            // Find the qcCommunities for the batch community
            const qcCommunityNames = this.getQCCommunities(item.name_eng__c);

            qcCommunityNames.forEach(qcCommunityName => {
                // Fetch QC Community details
                const lookupQCCommunity = this.getCommunityByName(qcCommunityName);

                // If the QC Community exists, add it to the batch details
                if (lookupQCCommunity != null && !this.qcCommunitiesInfo.some(element => element.name === lookupQCCommunity.name)) {
                    this.qcCommunitiesInfo.push(lookupQCCommunity);
                }
            });

            console.log(this.CLASS_NAME + '.buildQcCommunitiesInfo() qcCommunitiesInfo:');
            console.table(this.qcCommunitiesInfo);
        });

        // If no QC Communities found, add the default (if needed)
        if (this.qcCommunitiesInfo.length === 0) {
            const defaultCommunity = this.getCommunityByName('default');
            if (defaultCommunity != null) {
                this.qcCommunitiesInfo.push(defaultCommunity);
            }
        }

        return this.qcCommunitiesInfo;
    }
}
