import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';

import { BaseService } from './base.service';


@Injectable()
export class MarketapiProvider extends BaseService {
    private readonly CLASS_NAME = 'MarketapiProvider';


    getTransaction(order: string) {
        console.log(this.CLASS_NAME + '.getTransaction()', order);

        const url = `${MarketapiProvider.MARKET_URL}/api/transactions/view`;
        console.log(this.CLASS_NAME + '.getTransaction() - url: ', url);

        const options = {
            params: new HttpParams().append('order_reference', order)
        }

        return this.httpClient.get(url, options);
    }


    // get the name of the buyer of a given batch tag from the marketplace server
    getBuyerName(batch_tag: string) {
        console.log(this.CLASS_NAME + '.getBuyerName()', batch_tag);

        const url = `${MarketapiProvider.MARKET_URL}/api/transactions/buyer-by-tag`;
        console.log(this.CLASS_NAME + '.getBuyerName() - url', url);

        const body = {
            batch_tag: batch_tag
        };

        return this.httpClient.post(url, body);
    }

    // get the name of the buyer of a given batch tag from the marketplace server
    // New version that user for_specific_buyer field on listing to lookup buyer
    getBuyerNameNew(batch_tag: string) {
        console.log(this.CLASS_NAME + '.getBuyerNameNew()', batch_tag);

        const url = `${MarketapiProvider.MARKET_URL}/api/listings/buyer-by-tag`;
        console.log(this.CLASS_NAME + '.getBuyerNameNew() - url', url);

        const body = {
            batch_tag: batch_tag
        };

        return this.httpClient.post(url, body);
    }


    getListingDate(tag: string) {
        console.log(this.CLASS_NAME + '.getListingDate()', tag);

        const url = `${MarketapiProvider.MARKET_URL}/api/listings/listing-date`;
        console.log(this.CLASS_NAME + '.getListingDate() - url', url);

        const body = {
            tag: tag
        };

        return this.httpClient.post(url, body);
    }


    getListingExpiryState(tag: string) {

        const url = `${MarketapiProvider .MARKET_URL}/api/listings/does-expire`;
        console.log('', url);

        const body = {
            tag: tag
        };

        return this.httpClient.post(url, body);
    }


    // get the name of the co-op associated with a given batch tag from the marketplace server
    getGroupName(batch_tag: string) {

        const url = `${MarketapiProvider.MARKET_URL}/api/groups/bybatchtag`;
        console.log('', url);

        const options = {
            params: new HttpParams().append('batch_tag', batch_tag)
        }

        return this.httpClient.get(url, options);
    }


    // creates an entry in listingviews MongoDB collection
    logView(batch_tag: string) {

        const url = `${MarketapiProvider.MARKET_URL}/api/listing-views/log-view`;
        console.log('', url);

        const body = {
            batch_tag: batch_tag
        };

        return this.httpClient.post(url, body);
    }
}
