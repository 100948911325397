import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, map, catchError, of, from } from 'rxjs';



//TODO: Add type safety and classes for any types
@Injectable()
export class FisherImageService {
    CLASS_NAME = 'FisherImageService';
    PLACE_HOLDER_IMAGE = 'assets/images/placeholder-person.svg';

    constructor(private httpClient: HttpClient) { }

    validateImageUrl(url: string): Observable<boolean> {
        return this.httpClient
            .get(url, { observe: 'response', responseType: 'blob' })
            .pipe(
            map((response: any) => response.status === 200),
            catchError(() => of(false)));
    }


    getFisherImage(fisher: any): Observable<string> {
        // Convert the async method into a promise
        const promise = this._getFisherImage(fisher);

        // Convert the promise into an observable
        return from(promise);
    }


    private async _getFisherImage(fisher: any): Promise<string> {
        const dummyTimestamp = new Date().getTime();
        const urls = [fisher.h2c_pic_profile__c, fisher.image_url__c];

        // For each url, check if it is valid
        for (let url of urls) {
            if (url == null || url.length === 0) {
                continue;
            }

            url += "?t=" + dummyTimestamp;

            const isValid = await this.validateImageUrl(url).toPromise();
            console.log(this.CLASS_NAME + '._getFisherImage() after this.validateImageUrl\n', url, isValid);

            if (isValid) {
                return url;
            }
        }

        // If none of the urls are valid, return the placeholder image
        return this.PLACE_HOLDER_IMAGE;
    }
}
