import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MarketapiProvider } from '../../services/marketplace.service';
import { Transaction } from '../../classes/transaction';
import * as $ from 'jquery';
import * as _ from 'lodash';

@Component({
    selector: 'app-transaction',
    templateUrl: './transaction.component.html',
    styleUrls: ['./transaction.component.scss']
})
export class TransactionComponent implements OnInit {
    readonly #CLASS_NAME = 'TransactionComponent';

    order = new Transaction();
    orderId = '';
    loading = false;

    // When true the transaction could not be found
    showError = false;

    constructor(
        public route: ActivatedRoute,
        public market: MarketapiProvider) {

    }


    ngOnInit() {
        // Remove global classes
        $(document).ready(() => {
            $('body').css('background-image', 'none');
        });

        this.loading = true;
        this.route.params.subscribe(params => {

            if (!params['id']) {
                this.showError = true;
                console.log('ID param not present');
                this.loading = false;

                return;
            }

            this.orderId = params['id'];
            console.log(this.#CLASS_NAME + '.ngOnInit() getting order details - orderId:', this.orderId);

            this.market.getTransaction(this.orderId).subscribe((result: any) => {
                this.order = result as Transaction;
                console.log(this.order);
                this.loading = false;
            }, error => {
                this.showError = true;
                this.loading = false;
                console.log(error);
            });

        });
    }


    unsnake(str: string) {
        return str
            .replace('_', ' ')
            .toLowerCase();
    }


    closeWindow(): void {
        window.close();
    }
}
