import { CatchInit } from "../interfaces/catch-init.interface";

export class Catch {
    id = '';

    species_name = '';
    species_info = '';

    fisher_name = '';
    fisher_image = '';
    h2c_blurb__c = '';

    fisher_h2c_profile = '';
    fisher_h2c_boat = '';

    community_name = '';
    community_lat!: number;
    community_lon!: number;
    community_info = '';

    SASSI_ID!: number;

    trip_crew_number = '';
    trip_boat_name = '';
    trip_boat_type = '';
    trip_weather = '';
    trip_sea_condition = ''
    trip_start_time = '';
    trip_end_time = '';
    trip_bait_used = '';

    constructor(init?: Partial<CatchInit>) {
        if (init) {
            Object.assign(this, init);
        }
    }

}
