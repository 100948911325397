<!-- Open Graph tags (metadata about this page for social media sharing) -->
<meta property="og:type" content="website" />
<meta property="og:title" content="Hook-to-Cook Abalobi" />
<meta property="og:description" content="Add a description of Abalobi here..." />
<meta property="og:image" content="https://cloudinary.com/console/media_library/asset/image/upload/cropped-abalobi512_ylljcf" />

<div class="catch-main-banner">

    <!-- Error message -->
    <div *ngIf="showError === true" class=" center-nocatch">
        <h1 class="section-header text-center" style="color: white">
            {{ 'CATCH_ONE_GOT_AWAY' | translate }}
        </h1>
        <div class="text-center subscript">{{ 'CATCH_TAG_NOT_REGISTERED' | translate }}</div>
        <div class="text-center">
            <img src="assets/images/hook.png" alt="" class="img-responsive hook-image">
        </div>
    </div>

    <!-- Loading spinner -->
    <div *ngIf="cCatch == null && cBatch == null && showError === false && noFisher === false">
        <app-gooey-loader></app-gooey-loader>
    </div>

    <!--If a batch exists-->
    <div *ngIf="cBatch !== null" class="container animated fadeIn">
        <!--Show all fishers-->
        <div class="">
            <h3 class="text-center cs-fish-blurb">{{ 'CATCH_DETAIL_MEAL_BY' | translate }}</h3>
        </div>
        <div class="row batch-fisher-row">
            <!-- fisher is a BatchFisher object, i is the index of the fisher shown in the BatchFisher[] array -->
            <div class="col-md-12 cs-grid-margin " *ngFor="let fisher of cBatch.fishers, let i = index">
                <!--Fisher Image-->
                <h1 class="cs-h2c-header text-center">{{ fisher.FirstName__c + " " + fisher.LastName__c }}</h1>
                <div class="text-center">

                    <!-- If fisher has an image show image -->
                    <img
                        *ngIf="fisher.h2c_pic_profile__c !== null && fisher.h2c_pic_profile__c !== undefined"
                        class="cs-column-image fisher-profile-image img-thumbnail animated bounce"
                        [src]="fisher.h2c_pic_profile__c" />

                    <!-- If fisher has no image show default image -->
                    <img
                        *ngIf="fisher.h2c_pic_profile__c === null || fisher.h2c_pic_profile__c === undefined"
                        class="cs-column-image fisher-profile-image img-thumbnail animated bounce"
                        src="https://res.cloudinary.com/techairos/image/upload/v1508848176/fisherPlaceholder_okjsoe.jpg" />
                </div>
                <!-- Show fisher blurb if available -->
                <div *ngIf="fisher.h2c_blurb__c != null">
                    <!-- See less (truncated blurb) -->
                    <div *ngIf="truncateBlurb[i]">
                        <p class="cs-writeup cs-writeup-dark">
                            {{ 'CATCH_DETAIL_FISHER_INFO' | translate }} {{ fisher.h2c_blurb__c | truncate : 160 }}
                        </p>
                        <button class="btn btn-primary" (click)="toggleTruncateBlurb(i)">See more</button>
                    </div>
                    <!-- See more (non-truncated blurb) -->
                    <div *ngIf="!truncateBlurb[i]">
                        <p class="cs-writeup cs-writeup-dark">
                            {{ 'CATCH_DETAIL_FISHER_INFORMATION' | translate }} {{ fisher.h2c_blurb__c }}
                        </p>
                        <button class="btn btn-primary" (click)="toggleTruncateBlurb(i)">
                            {{ 'SEE_LESS_BTN' | translate }}
                        </button>
                    </div>
                </div>
            </div>

            <!-- Show the name of the co-op that listed the catch (only if there is one, i.e. the name is not == null) -->
            <div class="text-center" *ngIf="batch_coop_name !== null && batch_coop_name !== undefined">
                <p class="cs-writeup cs-writeup-dark">{{ 'CATCH_DETAIL_ASSOCIATED_WITH' | translate }} {{ batch_coop_name }}</p>
            </div>
        </div>

        <!-- Send thank you message button -->
        <div class="text-center">
            <button class="btn btn-default btn-primary centered gief-margin"
                    data-toggle="modal"
                    data-target="#exampleModal">
                {{ 'CATCH_DETAIL_SEND_THANKYOU_SMS' | translate }}
            </button>
        </div>
        <br>
        <!-- Social media sharing -->
        <div class="text-center">
            <p class="cs-writeup cs-writeup-dark">{{ 'CATCH_DETAIL_SHARE_STORY' | translate }}</p>
            <!-- Facebook -->
            <a target="_blank"
                [href]="'https://www.facebook.com/sharer/sharer.php?u=http%3A%2F%2Fhooktocook.abalobi.info%2F%23%2Fcatch%2F'+ catchId +'&amp;src=sdkpreparse'"
                class="fb-xfbml-parse-ignore">
                <img class="share-buttons"
                    src="https://res.cloudinary.com/techairos/image/upload/v1519829272/facebook_zmalef.png"
                    alt="Share this on Facebook" />
            </a>
            <!-- Twitter -->
            <a [href]="'https://twitter.com/share?url=http%3A%2F%2Fhooktocook.abalobi.info%2F%23%2Fcatch%2F' + catchId + '&amp;text=I%20just%20traced%20the%20story%20behind%20my%20seafood%20all%20the%20way%20back%20to%20the%20small%2Dscale%20fisher%20who%20caught%20it%21%20See%20it%20here%3A&amp;hashtags=hooktocook,abalobi'"
                target="_blank">
                <img class="share-buttons"
                    src="https://res.cloudinary.com/techairos/image/upload/v1519829261/twitter_mhrgc0.png"
                    alt="Share this on Twitter" />
            </a>
        </div>

        <!-- BATCH: Show species info -->
        <div class="row cs-info-row ">
            <h3 class="text-center" style="color: white">{{ 'CATCH_DETAIL_CATCH' | translate }}</h3>

            <div *ngFor="let species of cBatch.species">
                <h1 class="section-header text-center cs-h2c-header">
                    <!--{{cCatch.species_name}}-->
                    {{ species.name_eng__c }}
                </h1>
                <div class="text-center">
                    <img class="img-responsive cs-middle-image" [src]="species.image_url__c" />
                </div>
                <div class="">
                    <div class="writeup-padder">
                        <p class="cs-writeup cs-writeup-dark">{{ species.Ablb_Species_Writeup__c }}</p>
                    </div>
                </div>
            </div>
        </div>

        <!--Show community info-->
        <div class="row cs-info-row  card card-2">
            <h3 class="text-center">T{{ 'CATCH_DETAIL_LANDED' | translate }}</h3>

            <div *ngFor="let community of cBatch.communities">
                <h1 class="section-header text-center">
                    {{ community.name_eng__c }}
                </h1>
                <div class="col-md-6 cs-grid-margin ">
                    <!--Community Info-->
                    <google-map
                        [options]="{
                            center: {lat: community.gps_lat__c , lng: community.gps_lon__c},
                            zoomControl: true,
                            disableDefaultUI: true,
                            streetViewControl: false
                        }"
                        width="100%" [height]="300">
                        <map-marker
                            [position]="{ lat: community.gps_lat__c, lng: community.gps_lon__c }"
                            [options]="markerOptions">
                        </map-marker>
                    </google-map>
                </div>
                <div class="col-md-6 cs-grid-margin">
                    <!--Community Map-->
                    <p class="cs-writeup"> {{ community.comments__c }}</p>
                </div>
            </div>
        </div>

        <!-- Show boat info  -->
        <div class="row batch-fisher-row">
            <h1 class="text-center cs-h2c-header" *ngIf="cBatch.boatUrls['found_h2c_pic_boat']; else else_block">
                {{ 'CATCH_DETAIL_BOATS_USED' | translate }}
            </h1>
            <ng-template #else_block>
                <h1 class="text-center cs-h2c-header">{{ 'CATCH_DETAIL_TYPE_BOATS_USED' | translate }}</h1>
            </ng-template>

            <div class="col-md-6 cs-grid-margin" *ngFor="let img_url of cBatch.boatUrls['boatImgUrls']">
                <img [src]="img_url" alt="" class="boat-type-image img-responsive img-thumbnail">
            </div>
        </div>
    </div>
</div>

<div class="cs-spacer"></div>

<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                    {{ 'CATCH_DETAIL_SEND_THANKYOU' | translate }}
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <br>
                <div class="form-group">
                    <label>{{ 'CATCH_DETAIL_MESSAGE_LABEL' | translate }}</label>
                    <textarea type="text"
                              maxlength="256"
                              cols="50"
                              rows="6"
                              class="form-control"
                              [(ngModel)]="thankYouMsg"
                              placeholder="Enter a message for the fishers">
                    </textarea>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">
                    {{ 'CANCEL' | translate }}
                </button>
                <button type="submit" class="btn btn-primary" (click)="sendThankYouSMS()" data-dismiss="modal">
                    {{ 'SUBMIT' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>
