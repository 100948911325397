<div class="container-fluid bg-light">
    <!-- Loading spinner -->
    <div *ngIf="!order && !showError">
        <app-gooey-loader></app-gooey-loader>
    </div>

    <!-- Error message -->
    <div id="error-msg-container" *ngIf="showError === true">
        <div id="error-msg-child-container" class="center-align">
            <h2 class="error-msg">
                {{ 'TRANSACTION_ERROR' | translate }}!
            </h2>
            <h5 class="error-msg">{{ 'TRANSACTION_NOT_FOUND' | translate }}</h5>
            <a routerLink=""><button id="return-home-button" class="btn waves-effect waves-light blue darken-3">{{
                    'GO_HOME' | translate }}</button></a>
        </div>
    </div>

    <div *ngIf="order && !showError">

        <div id="unsuccessful-msg" *ngIf="order.status === 'failed_canceled'" class="row center-align">
            <div class="container">
                <img src="/assets/icons/x-circle.svg" class="success"><br />
                <h5>{{ 'TRANSACTION_UNSUCCESSFUL' | translate }}</h5>
            </div>
        </div>

        <div id="successful-msg" *ngIf="order.status === 'payment_processed'" class="row center-align">
            <div class="container">
                <img src="/assets/icons/check-circle.svg"><br />
                <h5>{{ 'TRANSACTION_SUCCESSFUL' | translate }}</h5>
            </div>
        </div>
        <div *ngIf="!loading && !!order">
            <div class="row" id="content-row">
                <div class="col l12 m12 s12">
                    <ul class="list-group">
                        <li class="list-group-item">
                            <strong>
                                <p>{{order.addpay_payer}}</p>
                            </strong>
                        </li>
                        <li class="list-group-item" *ngIf="orderId">
                            <p><b>{{ 'TRANSACTION_REFERENCE' | translate }}</b> {{order.listing.batch_tag}}</p>
                        </li>
                        <li class="list-group-item" *ngIf="orderId">
                            <p style="text-transform: capitalize"><b>{{ 'TRANSACTION_STATUS' | translate}}</b>
                                {{unsnake(order.status)}}</p>
                        </li>
                    </ul>
                </div>
            </div>
            <!--
            <div class="row" id="content-row">
                <button class="btn btn-outline btn-success" (click)="closeWindow()">{{ 'TRANSACTION_CLOSE_BUTTON_LABEL' | translate}}</button>
            </div>
            -->
        </div>
    </div>
</div>
