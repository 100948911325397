import {Listing} from "./listing";
import {ListingCatch} from "./listingCatch";

export class Transaction {

    addpay_id = '';
    addpay_reference = '';
    order_reference = '';
    addpay_payer = '';
    buyerId = '';
    sellerId = '';
    amount = 0;
    listing = new Listing();
    status = '';
    createdAt = '';
    updatedAt = '';
    id = '';

    constructor() {

    }
}

