/*============================================================================
    Angular Imports
 ============================================================================*/

import { Component, OnInit, Input, OnChanges } from '@angular/core';

/*============================================================================
    Custom Imports
 ============================================================================*/

import { Catch } from '../../classes/catch';

/*============================================================================
    Component Declaration
 ============================================================================*/

@Component({
    selector: 'app-info-table',
    templateUrl: './info-table.component.html',
    styleUrls: ['./info-table.component.scss'],
})

export class InfoTableComponent implements OnInit, OnChanges {

    @Input() cc: Catch = new Catch();

    timeDiff = '';
    showTripDuration = false;
    boat_img_url = '';

    constructor() { }

    ngOnInit() {
        console.log(new Date(this.cc.trip_start_time));
        console.log(new Date(this.cc.trip_end_time));

        // Just for now
        // this.cc.trip_boat_type = 'bakkie';


        this.updateStringsOnChange();
        // this.getBoatTypeImage();

        if (
            this.cc.trip_end_time === undefined
            || this.cc.trip_start_time === undefined
            || this.cc.trip_end_time === null
            || this.cc.trip_start_time === null
            || this.tripIsInvalid(this.cc.trip_start_time, this.cc.trip_end_time)
        ) {
            // If there are errors, do not show the trip duration
            this.showTripDuration = false;

        } else {
            this.timeDiff = this.parseMillisecondsIntoReadableTime(
                (new Date(this.cc.trip_end_time)).getTime() - (new Date(this.cc.trip_start_time)).getTime()
            );
            this.showTripDuration = true;
        }
    }

    ngOnChanges() {
        // console.log(changes);
        this.updateStringsOnChange();
        // this.getBoatTypeImage();
    }

    private parseMillisecondsIntoReadableTime(milliseconds: number): string {
        // Get hours from milliseconds
        const hours = milliseconds / (1000 * 60 * 60);
        const absoluteHours = Math.floor(hours);
        const h = absoluteHours > 9 ? absoluteHours : '0' + absoluteHours;

        // Get remainder from hours and convert to minutes
        const minutes = (hours - absoluteHours) * 60;
        const absoluteMinutes = Math.floor(minutes);
        const m = absoluteMinutes > 9 ? absoluteMinutes : '0' +  absoluteMinutes;

        // Get remainder from minutes and convert to seconds
        const seconds = (minutes - absoluteMinutes) * 60;
        const absoluteSeconds = Math.floor(seconds);
        const s = absoluteSeconds > 9 ? absoluteSeconds : '0' + absoluteSeconds;

        return h + ':' + m + ':' + s;
    }

    private cleanString(s: string): string {

        console.log(s);
        try{
            if (s === undefined || s === null) {
                return s;
            } else {
                s = s.replace('weather_', '');
                s = s.replace('sea_', '');
                s = s.replace('boat_', '');
                s = s.replace('_', ' ');
                return s.charAt(0).toUpperCase() + s.slice(1);
            }
        } catch (ex) {
            return s;
        }

    }

    private tripIsInvalid(startTime: string, endTime: string): boolean {

        // const compareDate = '2017-01-06T03:00:00.000+0000';
        const splitStart = startTime.split('T');
        const splitEnd = endTime.split('T');

        // Make sure the start time is not the default start time
        const TRIP_DEFAULT_START_TIME = ( splitStart[1] === '03:00:00.000+0000' );

        // Make sure the start and end times are not the same
        const TRIP_START_END_TIMES_SAME = ( splitStart[1] === splitEnd[1] );

        // Make sure none of the flags are true
        return ( TRIP_DEFAULT_START_TIME || TRIP_START_END_TIMES_SAME );
    }

    private updateStringsOnChange(): void {
        this.cc.trip_boat_name = this.cleanString(this.cc.trip_boat_name);
        this.cc.trip_boat_type = this.cleanString(this.cc.trip_boat_type);
        this.cc.trip_bait_used = this.cleanString(this.cc.trip_bait_used);
        this.cc.trip_weather = this.cleanString(this.cc.trip_weather);
        this.cc.trip_sea_condition = this.cleanString(this.cc.trip_sea_condition);
    }



}
