import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { UrlConfig } from '../shared/utils/urls/url-config.interface';


@Injectable({
    providedIn: 'root'
})
export abstract class BaseService {
    // private readonly CLASS_NAME = 'BaseService';

    protected httpClient = inject(HttpClient);

    public static SERVER_URL = '';
    public static MARKET_URL = '';
    public static PUBLIC_URL = '';
    public static ASSETS_URL = '';


    constructor() {
        console.log('BaseService.constructor()');
    }


    public static setUrls(tenantCode: string, urlConfig: UrlConfig): void {
        console.log('BaseService.setUrls()', urlConfig);

        BaseService.MARKET_URL = this.buildURL(tenantCode, urlConfig.MARKET);
        BaseService.SERVER_URL = this.buildURL(tenantCode, urlConfig.SERVER);
        BaseService.PUBLIC_URL = this.buildURL(tenantCode, urlConfig.PUBLIC);

        BaseService.ASSETS_URL = `${urlConfig.PUBLIC.protocol}://${urlConfig.PUBLIC.prefix}.${urlConfig.PUBLIC.domain}${urlConfig.PUBLIC.port === '' ? '' : urlConfig.PUBLIC.port}`;

        console.log(
            'BaseService.setUrls():', '\n',
            'SERVER_URL', BaseService.SERVER_URL, '\n',
            'MARKETPLACE_URL', BaseService.MARKET_URL, '\n',
            'PUBLIC_URL', BaseService.PUBLIC_URL, '\n',
            'ASSETS_URL', BaseService.ASSETS_URL
        );
    }


    private static buildURL(tenantCode: string, urlConfig: { protocol: string, prefix: string, domain: string, port: string }) {
        console.log('BaseService.buildURL()', urlConfig);

        return `${urlConfig.protocol}://${urlConfig.prefix}-${tenantCode}.${urlConfig.domain}${urlConfig.port === '' ? '' : urlConfig.port}`;
    }
}
