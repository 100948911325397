import {Injectable} from '@angular/core';

import { BaseService } from './base.service';


// This service returns the default quotes and videos for display in H2C if no other quotes and videos are available

@Injectable()
export class DefaultInfoService extends BaseService {
    private readonly CLASS_NAME = 'DefaultInfoService';

    defaultQuotes = [
        {
            name: 'Nico Waldeck, ABALOBI Co-founder',
            quote: 'ABALOBI is about bringing transparency and traceability into the seafood sector. It\'s about transformation, from hook to cook.',
            image: ''
        },
        {
            name: 'Dr Serge Raemaekers, ABALOBI Co-founder',
            quote: 'ABALOBI seeks to stimulate fishers\' agency, for fishers to be more empowered in the market - building their entrepreneurial confidence and aspirations.',
            image: ''
        },
        {
            name: '',
            quote: 'ABALOBI is proud to work with chefs who are willing to prioritise the concept of provenance. Who are up for the challenge of honouring what the sea yields when resources are harvested responsibly and with low environmental impact. Who are willing to diversify and experiment with under-represented species. Who are invested in creating consciousness about ethical food systems, and redefining the importance of ecologically responsible and socially fair fisheries. Who understand that they too have a role as ambassadors in ocean stewardship.',
             image: ''
            },
        {
            name: 'Dr Serge Raemaekers, ABALOBI Co-founder',
            quote: 'What if chefs, retailers, consumers like you and me could access \'storied\' and traceable seafood, powered by modern but community-owned technology, that also produces the data we need for the rebuilding of our fisheries, on a foundation of deep local ecological knowledge and partnerships? This is the journey we have embarked on. This is how fishers are empowering themselves and their communities.',
            image: ''
        }
    ];

    defaultVideo = {
        url : 'https://www.youtube.com/embed/bvdV7jvN2XE',
        intro: ''
    };


    getDefaultQuotes() {
        console.log(this.CLASS_NAME + '.getDefaultQuotes()');

        const url = `${DefaultInfoService.SERVER_URL}/api/fixed-quotes`;
        console.log(this.CLASS_NAME + '.getDefaultQuotes() - url', url);

        return this.httpClient.get(url);
    }


    getDefaultVideo() {
        return this.defaultVideo;
    }
}
