/*============================================================================
    Angular imports, module imports
 ============================================================================*/
import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

// import ngx-translate and the http loader
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { MarketapiProvider } from './services/marketplace.service';
import { AppInitService } from './app-init.service';

/*============================================================================
    Component Imports
 ============================================================================*/
import { AppComponent } from './app.component';
import { CatchDetailComponent } from './pages/catch-detail/catch-detail.component';
import { CatchService } from './services/catch.service';
import { SpeciesInfoService } from './services/species-info.service';
import { GooeyLoaderComponent } from './components/gooey-loader/gooey-loader.component';

import { CatchComponent } from './pages/catch/catch.component';

/*============================================================================
    Route Imports
 ============================================================================*/

import { AppRoutingModule } from './app-routing.module';
import { InfoTableComponent } from './components/info-table/info-table.component';
import { TransactionComponent } from './pages/transaction/transaction.component';
import { HomeComponent } from './pages/home/home.component';
import { SafePipe } from './pipes/safe.pipe';
import { TruncatePipe } from './pipes/truncate.pipe';
import { DefaultInfoService } from './services/default-info.service';
import { TransactionFailedComponent } from './pages/transaction-failed/transaction-failed.component';
import { HtmlFormatterDirective } from './directives/html-formatter.directive';
import { FisherImageService } from './services/fisher-image.service';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { CommunityInfoService } from './services/community-info.service';
import { GoogleMapsModule } from '@angular/google-maps'

/*============================================================================
    Other
 ============================================================================*/

function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}


function initializeApp(appInitService: AppInitService) {
    return async () => {
        await appInitService.init();
    };
}


@NgModule({
    declarations: [
        AppComponent,
        CatchDetailComponent,
        CatchComponent,
        HomeComponent,
        GooeyLoaderComponent,
        InfoTableComponent,
        TransactionComponent,
        TruncatePipe,
        SafePipe,
        TransactionFailedComponent,
        HtmlFormatterDirective
    ],
    imports: [
        BrowserModule,
        FormsModule,
        HttpClientModule,
        HttpClientModule,
        AppRoutingModule,
        GoogleMapsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [ HttpClient ]
            }
        })
    ],
    providers: [
        AppInitService,
        CatchService,
        SpeciesInfoService,
        MarketapiProvider,
        DefaultInfoService,
        CommunityInfoService,
        FisherImageService,
        {
            provide: APP_INITIALIZER,
            useFactory: initializeApp,
            deps: [ AppInitService ],
            multi: true
          }
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {
}
