import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { environment } from '../../../environments/environment';

declare const $: any;



@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

    version = environment.version;
    currYear = new Date().getFullYear();
    typewriterText = '';
    typewriterDisplay = '';
    tagToFind = '';


    constructor(
        private router: Router,
        private translate: TranslateService) {

    }


    ngOnInit() {

        // initialize slider
        /*   $(document).ready(function(){
               $('.slider').slider();
               $('.slider').slider('next');
           });
           */

        this.translate.get('INTRO_TEXT').subscribe((translated: string) => {

            this.typewriterText = translated;
            this.processUrl();
        });

        // initialize typewriter effect
        this.typewriter(this);
    }


    processUrl() {
        const urlParts = this.router.url.split('/');
        console.log(urlParts[0], urlParts[1], urlParts[2]);

        if (urlParts.length > 2 && urlParts[1] === '#') {
            urlParts.shift();
            urlParts.shift();
            try {
                this.router.navigate([urlParts.join('/')]);
            } catch (ex) { }
        }
    }


    typewriter(that: any): void {
        const totalLength = that.typewriterText.length;
        const currentLength = that.typewriterDisplay.length;


        if (currentLength < totalLength) {
            that.typewriterDisplay += that.typewriterText[currentLength];
        }

        // console.log('.typewriter()', that.typewriterDisplay); // DISABLED.

        if ((totalLength == 0) || currentLength < totalLength) {
            setTimeout(that.typewriter, 170, that);
        }
    }
}
