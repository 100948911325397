import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';

import {CatchDetailComponent} from './pages/catch-detail/catch-detail.component';
import {TransactionComponent} from "./pages/transaction/transaction.component";
import {CatchComponent} from "./pages/catch/catch.component";
import {HomeComponent} from "./pages/home/home.component";
import {TransactionFailedComponent} from "./pages/transaction-failed/transaction-failed.component";

const routes: Routes = [
    {
        // Default path
        path: '',
        component: HomeComponent
    },
    {
        path: 'catch',
        redirectTo: '',
        component: HomeComponent
    },
    {
        path: 'catch/:batch_tag',
        component: CatchComponent
    },
    {
        path: 'fisher/:fisher_id',
        component: CatchComponent
    },
    {
        path: 'community/:community_id',
        component: CatchComponent
    },
    {
        path: 'product/:product_id',
        component: CatchComponent
    },
    {
        path: 'species/:species_id',
        component: CatchComponent
    },
    {
        path: 'story/:establishment_id',
        component: CatchComponent
    },
    {
        path: 'catch-detail',
        component: CatchDetailComponent
    },
    {
        path: 'catch-detail/:id',
        component: CatchDetailComponent
    },
    {
        path: 'transaction',
        component: TransactionComponent
    },
    {
        path: 'transaction/:id',
        component: TransactionComponent
    },
    {
        path: 'transaction-failed',
        component: TransactionFailedComponent
    }
];

const routeSettings = {
    useHash: false
};

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forRoot(routes, routeSettings)
    ],
    declarations: [],
    exports: [RouterModule]
})


export class AppRoutingModule {
}
