import { Directive, AfterViewInit, ElementRef } from '@angular/core';

@Directive({
  selector: '[appHtmlFormatter]'
})
export class HtmlFormatterDirective implements AfterViewInit {

  constructor(private el: ElementRef) { }

  ngAfterViewInit() {
    let peeps = this.el.nativeElement.querySelectorAll('p');
    if (peeps) {
      for (let p of peeps) {
        p.style.fontSize = '1em';
      }
    }
  }

}
